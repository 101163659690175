import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PageHero from "../components/PageHero";
import PageContent from "../components/PageContent";
import "../styles/pages/consulting.scss";

class Consulting extends React.Component {
  render() {
    const pageName = "consulting";

    return (
      <Layout location={this.props.location} pageName={pageName}>
        <SEO
          title="Consulting"
          keywords={[`consulting`, `custom software`,`development`, `javascript`, `react`, `devops`, `ruby`, `rails`]}
        />
        <PageHero title="Ship better software faster.">
          <div className="page-hero__gradient-overlay" />
        </ PageHero>
        <PageContent>
          <section className="consulting__section">
            <h2>What can Deft do for me?</h2>
            <p>
              Deft provides technical teams at high-growth SaaS companies with the tools and processes they need to scale successfully. No nonsense.
            </p>
            <p>
              We believe every team can be highly effective in consistently and quickly shipping new features that delight users, fix reported bugs, and scale without compromising speed or quality.
            </p>
            <p>
              Since every team’s needs and challenges are unique, we offer a range of services, so we can meet you at your specific stage of growth. Of course, we’re happy to customize these services to best serve your team.
            </p>
          </section>
        </PageContent>
      </Layout>
    )
  }
}

export default Consulting;
